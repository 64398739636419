import {Asset, AssetIdentity, ResponseCreateAssetBody, ResponseGetAsset} from "../../types/asset";
import {ResponseDocument} from "../../types/document";
import {HTTP, axios} from "../http";

const BASE_ASSETS_URL = `/assets`;
const BASE_DOC_URL = `/documents`;

export const createAsset = async (props: Asset): Promise<ResponseCreateAssetBody> => {
	if (props?.imageFile && props.imageFile[0]) {
		const {id, filePath} = await uploadFile(props.imageFile[0], true);
		props.image = id;
		props.imagePath = filePath;
	}
	const asset = props;
	return (await HTTP.post(`${BASE_ASSETS_URL}`, asset)) as ResponseCreateAssetBody;
};

export const getAssetListByType = async (props: AssetIdentity): Promise<ResponseGetAsset> => {
	const assetIdentity = props;
	const result = await HTTP.get<any>(`${BASE_ASSETS_URL}/${assetIdentity.type}`);
	return result.data as ResponseGetAsset;
};

export const getAssetByIdentifier = async (props: AssetIdentity): Promise<Asset> => {
	const assetIdentity = props;
	const result = await HTTP.get<any>(
		`${BASE_ASSETS_URL}/${assetIdentity.type}/${assetIdentity.id}`,
	);
	return result.data.message[0] as Asset;
};

export const removeAssetByIdentifier = async (props: AssetIdentity): Promise<ResponseGetAsset> => {
	const assetIdentity = props;
	return (await HTTP.delete(
		`${BASE_ASSETS_URL}/${assetIdentity.type}/${assetIdentity.id}`,
	)) as ResponseGetAsset;
};

export const updateAsset = async (asset: Asset): Promise<ResponseGetAsset> => {
	if (asset?.imageFile && asset.imageFile[0]) {
		const {id, filePath} = await uploadFile(asset.imageFile[0], true);
		asset.image = id;
		asset.imagePath = filePath;
	}
	const assetIdentity = asset.identity;
	return (await HTTP.put(
		`${BASE_ASSETS_URL}/${assetIdentity?.type}/${assetIdentity?.id}`,
		asset,
	)) as ResponseGetAsset;
};

const uploadFile = async (
	file: File,
	isPublic = false,
): Promise<{id: string; filePath: string}> => {
	try {
		let responseDocument: ResponseDocument | any = null;
		const fileNameSplit = file.name.split(".");
		const fileExtension = fileNameSplit[fileNameSplit.length - 1];
		const name = file.name.replace(`.${fileExtension}`, "");
		const documentType = BASE_ASSETS_URL + "/images";
		const contentType = file.type;
		const bodyUpload = {
			name,
			fileExtension,
			documentType,
			contentType,
			isPublic,
		};
		const result = await HTTP.post<any>(`${BASE_DOC_URL}`, bodyUpload);
		responseDocument = result.data as ResponseDocument;
		if (!responseDocument.fileUrl) {
			console.log("Error on generate file url", responseDocument);
			throw new Error("Something went wrong on file upload.");
		}
		// notice: axios directly because aws s3 url interceptor Authorization header error
		const resultUpload = await axios({
			method: "put",
			url: responseDocument.fileUrl,
			data: file,
			headers: {"Content-Type": file.type},
		});
		if (resultUpload.status !== 200) {
			console.error("Error on file upload", resultUpload.data);
			throw new Error("Something went wrong on file upload.");
		}
		const resultStatus = await HTTP.patch<any>(`${BASE_DOC_URL}/${responseDocument.id}`, {
			status: "ACTIVE",
		});
		if (resultStatus.status !== 200) {
			console.error("Error on updating file upload status", resultStatus.data.message);
			throw new Error("Somethin went wrong on file upload.");
		}
		return {id: responseDocument.id, filePath: responseDocument.filePath};
	} catch (error) {
		throw error;
	}
};
