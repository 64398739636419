// Component generated with util/vox-create-component.js
import React from "react";
import {FormAssetWrapper} from "./FormAsset.styled";
import {FormAssetProps, FormAssetSchema} from "./FormAsset.types";
import {useForm} from "react-hook-form";
import {string, SchemaOf, object, mixed} from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import * as C from "./../../component";
import * as U from "./../../ui";
import {
	createAsset,
	updateAsset,
	getAssetListByType,
	removeAssetByIdentifier,
} from "../../service/api/assets-api";
import {Asset, AssetIdentity, ResponseCreateAssetBody, ResponseGetAsset} from "../../types/asset";
import DetailOds from "./Impact/DetailODS";
import {getOdsByAssetList, getThemeByAssetList} from "../../service/api/impact-api";
import DetailTheme from "./Impact/DetailTheme";

const FormAssetResolver: SchemaOf<FormAssetSchema> = object().shape({
	id: string(),
	type: string().required(),
	name: string().required(),
	description: string().required(),
	risks: string().required(),
	prospect: string().required(),
	imageFile: mixed().notRequired(),
});

export const FormAsset: React.VFC<FormAssetProps> = ({...args}) => {
	// Context Here
	// States Here
	const [list, setList] = React.useState<any>([]);
	const [showForm, setShowForm] = React.useState<boolean>(false);
	const [currentDelete, setCurrentDelete] = React.useState<any>(null);
	const [currentAsset, setCurrentAsset] = React.useState<any>(null);
	const [loading, setLoading] = React.useState<boolean>(false);
	const [loadingData, setLoadingData] = React.useState<boolean>(true);
	const [error] = React.useState<boolean>(false);
	// Hooks
	const {
		register,
		handleSubmit,
		formState: {errors},
		trigger,
		reset,
	} = useForm<FormAssetSchema>({
		resolver: yupResolver(FormAssetResolver),
		mode: "onSubmit",
	});

	// Effects Here
	React.useEffect(() => {
		(async () => {
			setLoadingData(true);
			// Faz a chamada do api
			getAssetListByTypeHandler();
		})();
	}, []);
	// Handlers Here

	const getAssetListByTypeHandler = () => {
		getAssetListByType({type: "BUSINESS"})
			.then((result: ResponseGetAsset) => {
				const resultList = result.message as Asset[];
				setList(resultList);
				setLoadingData(false);
			})
			.catch((reason: any) => {
				return () => setList([]);
			});
	};

	const clearForm = () => {
		// Adicionar os inputs para reiniciar aqui
		reset({
			id: "",
			type: "BUSINESS",
			name: "",
			description: "",
			risks: "",
			prospect: "",
			imageFile: "",
		});
	};

	const onEdit = async (row: any) => {
		const value: any = {
			id: row.values["identity.id"],
			type: row.values["identity.type"],
			name: row.values.name,
			description: row.values.description,
			risks: row.values.risks,
			prospect: row.values.prospect,
			imageFile: row.values.imageFile,
		};
		value.odsList = await getOdsByAssetList(value.id);
		value.themeList = await getThemeByAssetList(value.id);
		reset(value);
		setCurrentAsset(value);
		trigger();
		toogle();
	};
	const onAdd = () => {
		clearForm();
		toogle();
	};
	const toogle = () => {
		setShowForm(!showForm);
	};
	const deleteItem = async () => {
		setLoading(true);
		// Chamar o rest api para salvar as informações
		const assetIdentity = currentDelete.original.identity as AssetIdentity;
		console.log(assetIdentity);
		setCurrentDelete(null);
		setLoading(false);
		removeAssetByIdentifier(assetIdentity).then((response: ResponseGetAsset) => {
			setCurrentDelete(null);
			setLoading(false);
			if (response.status !== "success") {
				console.log(response.message);
			}
			getAssetListByTypeHandler();
		});
	};
	const onSubmit = handleSubmit(async (data) => {
		setLoading(true);
		console.log("data", data);
		const asset: Asset = {
			type: data.type,
			name: data.name,
			description: data.description,
			risks: data.risks,
			prospect: data.prospect,
		};
		let response: ResponseCreateAssetBody | ResponseGetAsset;
		// Chamar o rest api para salvar as informações
		if (data.id) {
			asset.identity = {
				id: data.id,
				type: data.type,
			};
			response = await updateAsset(asset);
		} else {
			console.log("Create", asset);
			response = await createAsset(asset);
		}
		if (response.status !== "success") {
			console.log("Error: ", response.message);
		}
		getAssetListByTypeHandler();
		setLoading(false);
		toogle();
	});
	// Component
	return (
		<FormAssetWrapper {...args}>
			{currentDelete && (
				<C.CPNAlert
					isModal
					title="Eliminar"
					description="Tem certeza que deseja eliminar este item?"
					type="INFO"
					loading={loading}
					onCancel={() => !loading && setCurrentDelete(null)}
					onAccept={() => !loading && deleteItem()}
				/>
			)}
			<div className="header flex justify-between items-center mb-4">
				<div className="title mb-4 flex flex-col pb-2">
					<U.UIText preset="HEADLINE_05">Ativos</U.UIText>
					<U.UIText preset="BODY">Relação de ativos cadastrados: Empresas, Crédito, etc </U.UIText>
				</div>
				<div className={showForm ? "hidden" : "action"}>
					<U.UIButton icon="addCircle" label="Adicionar" outline onClick={onAdd} />
				</div>
			</div>
			<div className={!showForm ? "hidden" : "forms"}>
				<div className="form">
					<div className="input">
						<C.CPNTextInput label="Id" disabled={true} {...register("id")} />
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Tipo de Ativo"
							value="BUSINESS"
							error={errors.name?.message}
							disabled={true}
							{...register("type")}
						/>
						{/* <C.CPNSelectInput
							label="Tipo de Ativo"
							options={[
								{
									id: "BUSINESS",
									label: "Empresa",
									value: "BUSINESS",
								},
							]}
							type="SIMPLE" // Single check or multicheck
							error={errors.type?.message}
							disabled={loading}
							{...register("type")}
						/> */}
					</div>
					<div className="input">
						<C.CPNTextInput
							label="Nome"
							error={errors.name?.message}
							disabled={loading}
							{...register("name")}
						/>
					</div>
				</div>
				<div className="form">
					<div className="input">
						<C.CPNTextarea
							label="Descrição"
							maxLength={250}
							disabled={loading}
							error={errors.description?.message}
							{...register("description")}
						/>
					</div>
					<div className="input">
						<C.CPNTextarea
							label="Riscos"
							maxLength={350}
							disabled={loading}
							error={errors.risks?.message}
							{...register("risks")}
						/>
					</div>
				</div>
				<div className="form">
					<div className="input">
						<C.CPNTextarea
							label="Prospecto"
							maxLength={350}
							disabled={loading}
							error={errors.prospect?.message}
							{...register("prospect")}
						/>
					</div>
				</div>
				<div className="input group">
					<U.UIText preset="BODY_02">Imagem do Ativo</U.UIText>
					<C.CPNTextInput
						type="file"
						placeholder="Upload imagem"
						disabled={loading}
						{...register(`imageFile`)}
					/>
					<U.UIText preset="BODY_03" color="GREY">
						Adicione a imagem que ira aparecer no ativo
					</U.UIText>
				</div>
				<div className="action gap-4">
					<U.UIButton label="Cancelar" outline loading={loading} onClick={toogle} />
					<U.UIButton label="Enviar" loading={loading} onClick={onSubmit} />
				</div>
				<DetailOds odsListInput={currentAsset?.odsList} assetId={currentAsset?.id} error={error} />
				<DetailTheme
					themeListInput={currentAsset?.themeList}
					assetId={currentAsset?.id}
					error={error}
				/>
			</div>
			{loadingData ? (
				<div className="loading bg-primary-lightest p-4 flex gap-2 items-center rounded-lg">
					<div className="animate-spin">
						<U.UIICon name="loading" />
					</div>
					<U.UIText preset="SUBTITLE">Trazendo informações, porfavor aguarde...</U.UIText>
				</div>
			) : (
				<div className={showForm ? "hidden" : "table"}>
					{!list.length ? (
						<div className="bg-secondary-lightest p-4 flex gap-2 items-center rounded-lg">
							<U.UIText preset="SUBTITLE">Não há nada para mostrar</U.UIText>
						</div>
					) : (
						<C.CPNTable
							onEdit={onEdit}
							// onDelete={onDelete}
							columns={[
								{
									Header: "ID",
									accessor: "identity.id",
								},
								{
									Header: "Tipo",
									accessor: "identity.type",
								},
								{
									Header: "Nome",
									accessor: "name",
								},
								{
									Header: "Descrição",
									accessor: "description",
								},
								{
									Header: "Riscos",
									accessor: "risks",
								},
								{
									Header: "Prospectos",
									accessor: "prospect",
								},
								{
									accessor: "image",
								},
							]}
							data={list}
						/>
					)}
				</div>
			)}
		</FormAssetWrapper>
	);
};

export default FormAsset;
